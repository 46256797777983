import * as React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Code from "../common/class/Code";
import { CODETYPE } from "../common/enum/codeType";
import { CodeListServices } from "../common/services/CodeListServices";
import { CodeListValue } from "./../common/data/codeList";
import { ErrorCode } from "./ErrorCode";
import { FormSelectionFigure } from "./FormSelectionFigure";
import "./ModalCode.css";
import { PadNumber } from "./PadNumber";

export interface ModalCodeProps {
  isOpen: boolean;
  toggleModal?: () => void;
  changeMusic: () => void;
  stopCounterTimer: () => void;
}

export const ModalCode: React.FunctionComponent<ModalCodeProps> = (props: ModalCodeProps) => {
  const [code, setCode] = React.useState({
    id: -1,
    text: ""
  } as Code);
  const [text, setText] = React.useState("");
  React.useEffect(() => {
    if (props.isOpen === true) {
      setCode({
        id: -1,
        order: -1,
        text: "",
        stepDone: false,
        codeType: CODETYPE.TEXT
      });
    }
  }, [props.isOpen]);
  React.useEffect(() => {
    if (text !== "" && code.codeType !== CODETYPE.FIGUREFORM) {
      setText("");
    }
  }, [code]);
  return (
    <Modal isOpen={props.isOpen} toggle={closeModal} autoFocus={false}>
      <ModalHeader toggle={closeModal}>
        <h1>Code</h1>
      </ModalHeader>
      <ModalBody>
        {code.id !== -1 ? (
          code.id === 9999 ? (
            <ErrorCode />
          ) : code.codeType === CODETYPE.TEXT ? (
            code.text
          ) : (
            <FormSelectionFigure
              textToDisplay={code.text!}
              responsePossibly={code.figurePossibility!}
              onSelectFigure={(id: number) => {
                onSelectFigure(id, code, CodeListValue);
              }}
              imageTextToDisplay={code.textImage}
            />
          )
        ) : (
          <PadNumber validate={(code: string) => getCodeOrNone(code)} />
        )}
        <p>{text}</p>
      </ModalBody>
    </Modal>
  );

  function onSelectFigure(id: number, code: Code, codeList: Code[]) {
    if (id === code.figureResponseId) {
      if (code.nextStepId != null) {
        let nextStep: Code | undefined = codeList.find((codeParam: Code) => {
          return codeParam.id === code.nextStepId;
        });
        if (nextStep != null) {
          setCode(nextStep);
          if (nextStep.codeType === CODETYPE.FIGUREFORM) {
            setText("Réponse correct");
          }
          if (nextStep.finalCode === true) {
            props.stopCounterTimer();
          }
        }
      } else {
        setCode({
          id: -1,
          order: -1,
          text: "",
          stepDone: false,
          codeType: CODETYPE.TEXT
        });
      }
    } else {
      setText("Réponse incorrect");
    }
  }

  function getCodeOrNone(codeParam: string) {
    const codeConst: Code | undefined = CodeListServices.getCodeByCode(codeParam);
    if (codeConst != null && CodeListServices.checkPreviousCodeUnlock(codeConst)) {
      setCode(codeConst);
      codeConst.stepDone = true;
      CodeListServices.updateCode(codeConst);
      if (codeConst.changeMusic === true) {
        props.changeMusic();
      }
    } else {
      setCode({
        id: 9999,
        order: 9999,
        text: "Erreur ce n'est pas le bon code",
        stepDone: false,
        codeType: CODETYPE.TEXT
      });
    }
  }
  function closeModal() {
    if (props.toggleModal != null) {
      props.toggleModal();
    }
  }
};
