import * as React from "react";
import "./CountTimerDown.css";

export interface CountTimerDownProps {
  isStarted: boolean;
  disabledTimer: () => void;
}

export const CountTimerDown: React.FunctionComponent<CountTimerDownProps> = (props: CountTimerDownProps) => {
  return (
    <div className="app-count-timer-down">
      <Timer disabledTimer={props.disabledTimer} isStarted={props.isStarted} />
    </div>
  );
};

const Timer: React.FunctionComponent<CountTimerDownProps> = (props: CountTimerDownProps) => {
  const COUNTDOWN_SECONDS = 1800;
  const [second, setSecond] = React.useState(COUNTDOWN_SECONDS);

  React.useEffect(() => {
    let interval: any;
    if (props.isStarted) {
      interval = setInterval(() => {
        setSecond(preSecond => {
          if (preSecond <= 1) {
            props.disabledTimer();
            clearInterval(interval);
            return COUNTDOWN_SECONDS;
          } else {
            return preSecond - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [props.isStarted]);

  return (
    <div onClick={() => setSecond(second - 100)} className={`timer ${COUNTDOWN_SECONDS * 0.25 > second ? "times-up-arround" : ""}`}>
      <div className="count-down">
        <h1>{("0" + Math.floor(second / 60)).slice(-2)}</h1>
      </div>
      <div className="separator">
        <h1>:</h1>
      </div>
      <div className="count-down">
        <h1>{("0" + (second % 60)).slice(-2)}</h1>
      </div>
    </div>
  );
};
