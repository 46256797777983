import Code from "../class/Code";
import { CODETYPE } from "../enum/codeType";
import aFichier_1 from "./../../ressources/images/aFichier 1.svg";
import aFichier_5 from "./../../ressources/images/aFichier 5.svg";
import aFichier_7 from "./../../ressources/images/aFichier 7.svg";
import aFichier_8 from "./../../ressources/images/aFichier 8.svg";
import bFichier_10 from "./../../ressources/images/bFichier 10.svg";
import Fichier_14 from "./../../ressources/images/Fichier 14.svg";
import Fichier_17 from "./../../ressources/images/Fichier 17.svg";
import Fichier_18 from "./../../ressources/images/Fichier 18.svg";
import Fichier_19 from "./../../ressources/images/Fichier 19.svg";
import Fichier_20 from "./../../ressources/images/Fichier 20.svg";
import Fichier_21 from "./../../ressources/images/Fichier 21.svg";
import Fichier_22 from "./../../ressources/images/Fichier 22.svg";
import Fichier_23 from "./../../ressources/images/Fichier 23.svg";
import Fichier_25 from "./../../ressources/images/Fichier 25.svg";
import Fichier_27 from "./../../ressources/images/Fichier 27.svg";
import Fichier_28 from "./../../ressources/images/Fichier 28.svg";
import intero_bleu from "./../../ressources/images/intero_bleu.svg";
import intero_rose from "./../../ressources/images/intero_rose.svg";
import intero_rouge from "./../../ressources/images/intero_rouge.svg";
import intero_vert from "./../../ressources/images/intero_vert.svg";

export const CodeListValue: Code[] = [
  {
    id: 2402,
    order: 1,
    text: "prendre la carte 25",
    stepDone: false,
    codeType: CODETYPE.TEXT
  },
  {
    id: 11,
    order: 2,
    text: "prendre la carte 55",
    stepDone: false,
    codeType: CODETYPE.TEXT
  },
  {
    id: 4831,
    order: 3,
    text: "prendre la carte 93",
    stepDone: false,
    codeType: CODETYPE.TEXT
  },
  {
    id: 4313,
    order: 4,
    text: "prendre la carte 39",
    stepDone: false,
    codeType: CODETYPE.TEXT
  },
  {
    id: 100,
    order: 0,
    text: "Bravo, prendre la carte 100",
    stepDone: false,
    finalCode: true,
    codeType: CODETYPE.TEXT
  },
  {
    id: 78,
    order: 0,
    text: "Quel est le premier super-héros ?",
    stepDone: false,
    codeType: CODETYPE.FIGUREFORM,
    nextStepId: 7801,
    changeMusic: true,
    figurePossibility: [
      {
        id: 0,
        imgProperties: Fichier_21
      },
      {
        id: 1,
        imgProperties: Fichier_14
      },
      {
        id: 2,
        imgProperties: Fichier_23
      },
      {
        id: 3,
        imgProperties: Fichier_22
      }
    ],
    figureResponseId: 0
  },
  {
    id: 7801,
    order: 0,
    text: "Quel est le second super-héros ?",
    stepDone: false,
    codeType: CODETYPE.FIGUREFORM,
    nextStepId: 7802,
    figurePossibility: [
      {
        id: 0,
        imgProperties: Fichier_14
      },
      {
        id: 1,
        imgProperties: Fichier_21
      },
      {
        id: 2,
        imgProperties: Fichier_23
      },
      {
        id: 3,
        imgProperties: Fichier_22
      }
    ],
    figureResponseId: 2
  },
  {
    id: 7802,
    order: 0,
    text: "Quel est le troisième super-héros ?",
    stepDone: false,
    nextStepId: 7803,
    codeType: CODETYPE.FIGUREFORM,
    figurePossibility: [
      {
        id: 0,
        imgProperties: Fichier_21
      },
      {
        id: 1,
        imgProperties: Fichier_22
      },
      {
        id: 2,
        imgProperties: Fichier_28
      },
      {
        id: 3,
        imgProperties: Fichier_23
      }
    ],
    figureResponseId: 1
  },
  {
    id: 7803,
    order: 0,
    text: "Insérer le super-héros de forme",
    textImage: {
      id: 0,
      imgProperties: aFichier_7
    },
    stepDone: false,
    nextStepId: 7804,
    codeType: CODETYPE.FIGUREFORM,
    figurePossibility: [
      {
        id: 0,
        imgProperties: Fichier_17
      },
      {
        id: 1,
        imgProperties: Fichier_20
      },
      {
        id: 2,
        imgProperties: Fichier_18
      },
      {
        id: 3,
        imgProperties: Fichier_19
      }
    ],
    figureResponseId: 1
  },
  {
    id: 7804,
    order: 0,
    text: "insérer le super-héros de forme",
    textImage: {
      id: 0,
      imgProperties: aFichier_5
    },
    stepDone: false,
    nextStepId: 7805,
    codeType: CODETYPE.FIGUREFORM,
    figurePossibility: [
      {
        id: 0,
        imgProperties: Fichier_17
      },
      {
        id: 1,
        imgProperties: Fichier_18
      },
      {
        id: 2,
        imgProperties: Fichier_20
      },
      {
        id: 3,
        imgProperties: Fichier_19
      }
    ],
    figureResponseId: 3
  },
  {
    id: 7805,
    order: 0,
    text: "insérer le super super-héros",
    textImage: {
      id: 0,
      imgProperties: Fichier_18
    },
    stepDone: false,
    nextStepId: 7806,
    codeType: CODETYPE.FIGUREFORM,
    figurePossibility: [
      {
        id: 0,
        imgProperties: intero_rose
      },
      {
        id: 1,
        imgProperties: intero_vert
      },
      {
        id: 2,
        imgProperties: intero_bleu
      },
      {
        id: 3,
        imgProperties: intero_rouge
      }
    ],
    figureResponseId: 2
  },
  {
    id: 7806,
    order: 0,
    text: "insérer le super super-héros",
    textImage: {
      id: 0,
      imgProperties: Fichier_19
    },
    stepDone: false,
    nextStepId: 7807,
    codeType: CODETYPE.FIGUREFORM,
    figurePossibility: [
      {
        id: 0,
        imgProperties: aFichier_1
      },
      {
        id: 1,
        imgProperties: aFichier_5
      },
      {
        id: 2,
        imgProperties: aFichier_8
      },
      {
        id: 3,
        imgProperties: aFichier_7
      }
    ],
    figureResponseId: 1
  },
  {
    id: 7807,
    order: 0,
    text: "Quel est le onzième super-héros ?",
    stepDone: false,
    nextStepId: 7808,
    codeType: CODETYPE.FIGUREFORM,
    figurePossibility: [
      {
        id: 0,
        imgProperties: Fichier_28
      },
      {
        id: 1,
        imgProperties: Fichier_21
      },
      {
        id: 2,
        imgProperties: Fichier_27
      },
      {
        id: 3,
        imgProperties: Fichier_25
      }
    ],
    figureResponseId: 0
  },
  {
    id: 7808,
    order: 0,
    text: "Insérer la forme de",
    textImage: {
      id: 0,
      imgProperties: Fichier_17
    },
    stepDone: false,
    nextStepId: 100,
    codeType: CODETYPE.FIGUREFORM,
    figurePossibility: [
      {
        id: 0,
        imgProperties: intero_bleu
      },
      {
        id: 1,
        imgProperties: aFichier_1
      },
      {
        id: 2,
        imgProperties: intero_vert
      },
      {
        id: 3,
        imgProperties: aFichier_5
      },
      {
        id: 4,
        imgProperties: aFichier_8
      },
      {
        id: 5,
        imgProperties: intero_rouge
      },
      {
        id: 4,
        imgProperties: aFichier_7
      },
      {
        id: 5,
        imgProperties: intero_rose
      }
    ],
    figureResponseId: 1
  }
];
