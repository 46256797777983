import * as React from "react";
import * as Music1 from "./../ressources/sounds/ESCAPE 1_01.mp3";
import * as Music2 from "./../ressources/sounds/ESCAPE 2_01.mp3";
import "./Body.css";
import { CenterMenuButton } from "./CenterMenuButton";
import { CountTimerDown } from "./CountTimerDown";
import { ModalAdvice } from "./ModalAdvice";
import { ModalCode } from "./ModalCode";

export const Body: React.FunctionComponent<{}> = () => {
  const [modalAdviceIsOpen, setModalAdviceIsOpen] = React.useState(false);
  const [modalCodeIsOpen, setModalCodeIsOpen] = React.useState(false);
  const [gameIsStarted, setGameIsStarted] = React.useState(false);
  const [secondMusic, setSecondMusic] = React.useState(false);
  AudioPlayer.setAudioPlayer(gameIsStarted, secondMusic);
  return (
    <div className="app-body">
      <span className="app-body_visual-effect" />
      <span className="app-body_star" />
      <span className="app-body_top-left" />
      <span className="app-body_top-right" />

      <CountTimerDown isStarted={gameIsStarted} disabledTimer={() => setGameIsStarted(false)} />
      <CenterMenuButton
        clickPlay={() => setGameIsStarted(!gameIsStarted)}
        clickAdvice={() => setModalAdviceIsOpen(true)}
        clickCode={() => setModalCodeIsOpen(true)}
        gameIsStarted={gameIsStarted}
      />
      <ModalAdvice isOpen={modalAdviceIsOpen} toggleModal={() => setModalAdviceIsOpen(!modalAdviceIsOpen)} />
      <ModalCode stopCounterTimer={() => setGameIsStarted(false)} changeMusic={() => setSecondMusic(true)} isOpen={modalCodeIsOpen} toggleModal={() => setModalCodeIsOpen(!modalCodeIsOpen)} />
      <span className="app-body_bottom-left" />
      <span className="app-body_bottom-right" />
    </div>
  );
};

export class AudioPlayer {
  private static SinglotonAudioPlayer: HTMLAudioElement | undefined;
  private static SinglotonAudioPlayerMusicSrc: any = Music2;
  public static getAudioPlayer() {
    if( this.SinglotonAudioPlayer != null ) {
      return this.SinglotonAudioPlayer;
    } else {
      this.SinglotonAudioPlayer = new Audio();
      this.SinglotonAudioPlayer.controls=true;
      this.SinglotonAudioPlayer.autoplay=false;
      this.SinglotonAudioPlayer.hidden=true;
      this.SinglotonAudioPlayer.loop=true;
      this.SinglotonAudioPlayer.src = this.SinglotonAudioPlayerMusicSrc;
      return this.SinglotonAudioPlayer;
    }
  }

  public static setAudioPlayer(gameIsStarted: boolean, secondMusic: boolean) {
    this.getAudioPlayer();
      if(secondMusic === true && this.SinglotonAudioPlayerMusicSrc === Music2) {this.SinglotonAudioPlayerMusicSrc = Music1; this.SinglotonAudioPlayer!.src = this.SinglotonAudioPlayerMusicSrc;};
      if(gameIsStarted === true && this.SinglotonAudioPlayer!.paused === true) {
        this.SinglotonAudioPlayer!.play()
      }
      if(gameIsStarted === false && this.SinglotonAudioPlayer!.paused === false) {
        this.SinglotonAudioPlayer!.pause()
      }
      return this.SinglotonAudioPlayer;
  }
};
