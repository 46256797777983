import Advice from "../class/Advice";

export const AdviceList: Advice[] = [
  {
    id: 74,
    advice: "avez-vous bien observé le tableau ?"
  },
  {
    id: 63,
    advice: "avez-vous bien observé la carte ?"
  },
  {
    id: 9,
    advice: "il me faut des piles"
  },
  {
    id: 29,
    advice: "est-ce qu’on peut me démonter ?"
  },
  {
    id: 8,
    advice: "que pourrais-je démonter ?"
  },
  {
    id: 5,
    advice: "Comment éclairer le fond du tuyau ?"
  },
  {
    id: 51,
    advice: "Avez-vous découvert la carte cachée de la carte 74 ?"
  },
  {
    id: 64,
    advice: "Avez-vous bien lu le message ?"
  },
  {
    id: 7,
    advice: "Avez-vous ouvert le coffre-fort ?"
  },
  {
    id: 91,
    advice: "Avez-vous déjà découvert l’intelligence de Madison ?"
  },
  {
    id: 1,
    advice: "Une bibliothèque pas loin de vous ?"
  },
  {
    id: 59,
    advice: "Gardez le pour le cœur de madison"
  },

  {
    id: 82,
    advice: "Avez-vous bien regardé la carte 88 ?"
  },

  {
    id: 88,
    advice: "Avez-vous bien regardé la carte 23 ?"
  },

  {
    id: 23,
    advice: "Avez-vous bien regardé la carte 88 ?"
  },

  {
    id: 49,
    advice: "Avez-vous regardé la vue du hublot ?"
  },

  {
    id: 66,
    advice: "Avez-vous déjà découvert l’intelligence de Madison ?"
  },

  {
    id: 55,
    advice: "des chiffres cachés ?"
  },

  {
    id: 54,
    advice: "Avez-vous regardé sur le bureau ?"
  },

  {
    id: 73,
    advice: "Rien de particulier."
  },

  {
    id: 95,
    advice: "Avez-vous un objet à glisser dans l’encoche ?"
  },

  {
    id: 16,
    advice: "Comment se sent Igor aujourd’hui ?"
  },

  {
    id: 31,
    advice: "Sur la carte d’humeur qui représente Igor ?"
  },

  {
    id: 85,
    advice: "Quel personnage Igor représente-t-il ?"
  },

  {
    id: 36,
    advice: "Qui serait Igor sur le mur des humeurs ?"
  },

  {
    id: 41,
    advice: "Avez-vous déjà découvert l’intelligence de Madison ?"
  },

  {
    id: 96,
    advice: "rien à signaler"
  },

  {
    id: 93,
    advice: "que d’étoiles sur le mur"
  },

  {
    id: 28,
    advice: "Un objet pour l’ouvrir ?"
  },

  {
    id: 6,
    advice: "Qu’est-ce que l’équipe prendra peut-être lors d’un prochain sprint ?"
  },

  {
    id: 77,
    advice: "Qu’est-ce que l’équipe n’a pas commencé au cours de ce sprint ? Avez-vous pris en compte sa capacité et la valeur métier ?"
  },

  {
    id: 35,
    advice: "Qu’est-ce que l’équipe est en train de faire au cours de ce sprint ?"
  },

  {
    id: 19,
    advice: "Qu’est ce que l’équipe a déjà terminé au cours de ce sprint ?"
  },

  {
    id: 99,
    advice: "Avez-vous retourné toutes les cartes alphabétiques ?"
  },

  {
    id: 12,
    advice: "Observez bien le tableau de cette carte pour connaitre l’avancement de l’équipe. Puis compte le nombre de carte par colonne !"
  },

  {
    id: 87,
    advice: "Avez-vous déjà découvert l’intelligence de Madison ?"
  },

  {
    id: 39,
    advice: "Avez-vous bien 4 cartes avec une forme en haut à droite ?"
  },

  {
    id: 17,
    advice: "Ici nous lisons de droite à gauche et de haut en bas"
  },

  {
    id: 78,
    advice: "Avez-vous observé la carte 17 ?"
  },

  {
    id: 37,
    advice: "Elle fonctionne et peuvent servir!"
  },

  {
    id: 51,
    advice: "Une porte à ouvrir ?"
  },

  {
    id: 47,
    advice: "Comment ouvrir la porte ?"
  },

  {
    id: 98,
    advice: "3 cartes à retourner"
  }
];
