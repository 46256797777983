import * as React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Advice from "../common/class/Advice";
import { advicesAndCodeArraysUtils } from "../common/services/advicesAndCodeArraysUtils";
import { ErrorCode } from "./ErrorCode";
import "./ModalAdvice.css";
import { PadNumber } from "./PadNumber";

export interface ModalAdviceProps {
  isOpen: boolean;
  toggleModal?: () => void;
}

export const ModalAdvice: React.FunctionComponent<ModalAdviceProps> = (props: ModalAdviceProps) => {
  const [advice, setAdvice] = React.useState({
    id: -1,
    advice: ""
  } as Advice);
  React.useEffect(() => {
    if (props.isOpen === true) {
      setAdvice({
        id: -1,
        advice: ""
      });
    }
  }, [props.isOpen]);
  return (
    <Modal isOpen={props.isOpen} toggle={closeModal} autoFocus={false}>
      <ModalHeader toggle={closeModal}>
        <h1>Indice</h1>
      </ModalHeader>
      <ModalBody
        onClick={() => {
          if (advice.id !== -1) {
            setAdvice({
              id: -1,
              advice: ""
            });
          }
        }}
      >
        {advice.id !== -1 ? advice.id === 9999 ? <ErrorCode /> : advice.advice : <PadNumber validate={code => getAdviceOrNone(code)} />}
      </ModalBody>
    </Modal>
  );
  function getAdviceOrNone(code: string) {
    const advice: Advice | undefined = advicesAndCodeArraysUtils.getAdviceByCode(code);
    if (advice != null) {
      setAdvice(advice);
    } else {
      setAdvice({
        id: 9999,
        advice: "Erreur ce n'est pas le bon code"
      });
    }
  }
  function closeModal() {
    if (props.toggleModal != null) {
      props.toggleModal();
    }
  }
};
