import * as React from "react";
import "./CenterMenuButton.css";
import { RoundedButton } from "./RoundedButton";

export interface CenterMenuButtonProps {
  clickPlay: () => void;
  clickAdvice: () => void;
  clickCode: () => void;
  gameIsStarted: boolean;
}

export const CenterMenuButton: React.FunctionComponent<CenterMenuButtonProps> = (props: CenterMenuButtonProps) => {
  return (
    <div className="center-menu-button">
      <RoundedButton className="center-menu-button_idea-button" disabled={props.gameIsStarted === false} color="dark" onClick={props.clickAdvice} />
      {props.gameIsStarted === true ? (
        <RoundedButton className="center-menu-button_pause-button" color="success" onClick={props.clickPlay} />
      ) : (
        <RoundedButton className="center-menu-button_play-button" color="danger" onClick={props.clickPlay} />
      )}
      <RoundedButton className="center-menu-button_lock-button" disabled={props.gameIsStarted === false} color="dark" onClick={props.clickCode} />
    </div>
  );
};
