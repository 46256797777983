import * as React from "react";
import { Button, ButtonProps } from "reactstrap";
import "./RoundedButton.css";

export type RoundedButtonProps = ButtonProps & { children?: JSX.Element | string | number };

export const RoundedButton: React.FunctionComponent<RoundedButtonProps> = (props: RoundedButtonProps) => {
  return (
    <div className="rounded-button">
      <Button {...props}>{props.children}</Button>
    </div>
  );
};
