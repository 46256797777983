import * as React from "react";
import Figure from "../common/class/Figure";
import "./FormSelectionFigure.css";

export interface FormSelectionFigureProps {
  responsePossibly: Figure[];
  textToDisplay: string;
  imageTextToDisplay?: Figure;
  onSelectFigure: (id: number) => void;
}

export const FormSelectionFigure: React.FunctionComponent<FormSelectionFigureProps> = (props: FormSelectionFigureProps) => {
  return (
    <div className="form-selection-figure">
      <p>{props.textToDisplay}</p>
      {props.imageTextToDisplay != null && <img className="form-selection-figure_image-question" src={props.imageTextToDisplay!.imgProperties!} />}
      <div className="container">
        <div className="row">
          {props.responsePossibly.map((figure: Figure) => {
            return (
              <div className="col-auto">
                <button
                  className="figure-choice"
                  onClick={() => {
                    props.onSelectFigure(figure.id);
                  }}
                >
                  <img src={figure.imgProperties} />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
