import * as React from "react";
import errorCodeImg from "./../ressources/images/icon_error.svg";
import "./ErrorCode.css";

export const ErrorCode: React.FunctionComponent<{}> = () => {
  return (
    <div className="error-code">
      <div>Et non, ce n'est pas le bon code.</div>
      <div>Retentez votre chance !</div>
      <div>
        <img className="error-code_img" src={errorCodeImg} />
      </div>
    </div>
  );
};
