import Advice from "../class/Advice";
import { AdviceList } from "../data/advicesList";

export class advicesAndCodeArraysUtils {
  public static getAdviceByCode(code: string): Advice | undefined {
    let result: Advice | undefined;
    result = AdviceList.find((advice: Advice) => {
      return advice.id.toString() === code;
    });
    return result;
  }
}
