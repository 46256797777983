import * as React from "react";
import { Input } from "reactstrap";
import "./PadNumber.css";
import { RoundedButton } from "./RoundedButton";

export interface PadNumberProps {
  defaultCode?: string;
  validate: (code: string) => void;
}

export const PadNumber: React.FunctionComponent<PadNumberProps> = (props: PadNumberProps) => {
  const [code, setCode] = React.useState("");
  return (
    <form onSubmit={() => props.validate(code)}>
      <table className="pad-number">
        <thead>
          <tr>
            <th colSpan={3}>
              <Input
                onChange={(e: React.FormEvent<HTMLInputElement>) => setCode(e.currentTarget.value)}
                autoFocus={false}
                type="text"
                placeholder="Saisir votre code"
                value={code}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="square-button">
                <RoundedButton className="button-1" onClick={() => onclickValue("1")}>
                  1
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-2" onClick={() => onclickValue("2")}>
                  2
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-3" onClick={() => onclickValue("3")}>
                  3
                </RoundedButton>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="square-button">
                <RoundedButton className="button-4" onClick={() => onclickValue("4")}>
                  4
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-5" onClick={() => onclickValue("5")}>
                  5
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-6" onClick={() => onclickValue("6")}>
                  6
                </RoundedButton>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="square-button">
                <RoundedButton className="button-7" onClick={() => onclickValue("7")}>
                  7
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-8" onClick={() => onclickValue("8")}>
                  8
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-9" onClick={() => onclickValue("9")}>
                  9
                </RoundedButton>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="square-button">
                <RoundedButton className="button-c" color="danger" onClick={() => setCode("")}>
                  C
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-0" onClick={() => onclickValue("0")}>
                  0
                </RoundedButton>
              </div>
            </td>
            <td>
              <div className="square-button">
                <RoundedButton className="button-ok" color="success" type="submit">
                  OK
                </RoundedButton>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );

  function onclickValue(value: string) {
    let result: string = code;
    result += value;
    setCode(result);
  }
};
