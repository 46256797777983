import Code from "../class/Code";
import { CodeListValue } from "../data/codeList";

export class CodeListServices {
  public static updateCode(code: Code) {
    let index: number = CodeListValue.findIndex((codeItem: Code) => {
      return codeItem.id === code.id;
    });
    CodeListValue[index] = code;
  }

  public static getCodeByCode(code: string): Code | undefined {
    let result: Code | undefined;
    result = CodeListValue.find((codeItem: Code) => {
      return codeItem.id.toString() === code;
    });
    return result;
  }

  public static getCodeByOrder(orderValue: number): Code | undefined {
    let result: Code | undefined;
    result = CodeListValue.find((codeItem: Code) => {
      return codeItem.order === orderValue;
    });
    return result;
  }

  public static checkPreviousCodeUnlock(codeValue: Code): boolean {
    let result: boolean = false;
    if (codeValue.order > 1) {
      const codeUnlock: Code | undefined = this.getCodeByOrder(codeValue.order - 1);
      result = codeUnlock !== undefined && codeUnlock.stepDone === true;
    } else {
      result = true;
    }
    return result;
  }
}
